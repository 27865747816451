<template>
  <keep-alive :include="include">
    <router-view  />
  </keep-alive>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Authority',
  computed: {
    ...mapState([
      'include',
    ]),
  },
  watch: {
    include(val) {
      // console.log(val);
    },
  },
};
</script>
